<template>
  <Dialog
    header="ข้อกำหนดและเงื่อนไขการใช้งานระบบ"
    v-model:visible="$store.state.pdpa.isOpen"
    :headerStyle="{'font-size': '20px'}"
    :style="{ width: '75vw' }"
  >
    <h5><strong>นโยบายการคุ้มครองข้อมูลส่วนบุคคล</strong></h5>
    <p><strong>ข้อมูลทั่วไป</strong></p>
    <p>
      มูลนิธิสดศรี-สฤษดิ์วงศ์ จัดตั้งขึ้นเมื่อ พ.ศ.๒๕๓๗
      ตามเจตนารมณ์ของนายแพทย์สฤษดิ์วงศ์และคุณหญิงสดศรี วงศ์ถ้วยทอง
      โดยมีวัตถุประสงค์แรกเริ่มในการพัฒนาเด็กปัญญาเลิศ
      รวมทั้งสนับสนุนการพัฒนาคุณภาพมนุษย์เพื่อประโยชน์ของสังคมไทย ปัจจุบัน
      มูลนิธิมีเป้าหมายสำคัญในการสนับสนุนการพัฒนาทรัพยากรมนุษย์
      โดยเฉพาะอย่างยิ่งการส่งเสริมการพัฒนาทรัพยากรมนุษย์ให้มีศักยภาพในการพัฒนาตนเอง
      สังคม และสิ่งแวดล้อม พัฒนากระบวนการเรียนรู้และนวัตกรรมทางการศึกษา
      รวบรวมองค์ความรู้และศึกษาวิจัยในด้านการพัฒนาทรัพยากรมนุษย์ในสังคมไทย
      ไปจนถึงดำเนินการสาธารณะประโยชน์หรือร่วมมือกับองค์กรกุศลอื่นๆ
      เพื่อสาธารณะประโยชน์ด้านการพัฒนาทรัพยากรมนุษย์
    </p>
    <p>
      มูลนิธิสดศรี-สฤษดิ์วงศ์เคารพสิทธิ์ของผู้ใช้งานภายใต้กฎหมายคุ้มครองข้อมูลส่วนตัวและข้อบังคับที่เกี่ยวข้อง
      นโยบายการปกป้องข้อมูลส่วนตัวของเรามีผลบังคับใช้กับทุกประเทศที่เราดำเนินกิจกรรมนอกจากที่ระบุไว้
      มูลนิธิสดศรี-สฤษดิ์วงศ์เก็บ
      ใช้และเปิดเผยข้อมูลส่วนตัวของผู้ใช้งานเพื่อวัตถุประสงค์ตามที่แจ้งให้ผู้ใช้งานทราบในนโยบายฉบับนี้
      หรือตามที่กฎหมายและข้อบังคับอนุญาต เราหวังว่า
      ด้วยการแจ้งให้ผู้ใช้งานทราบถึงนโยบายของเรา
      จะทำให้ผู้ใช้งานอุ่นใจในการใช้งานเว็บไซต์และแอปพลิเคชันของเรา
    </p>
    <p>
      โดยการเข้าเยี่ยมชมเว็บไซต์ของเราและ/หรือใช้บริการของเราในฐานะสมาชิกที่ลงทะเบียน(รวมถึงแต่ไม่จำกัด
      เฉพาะผ่านทางเว็บไซต์และ/หรือแอปพลิเคชันบนมือถือ)ผู้ใช้งานยอมรับและยินยอมให้มีการเก็บ
      ใช้และเปิดเผยข้อมูลส่วนตัวของผู้ใช้งานตามที่อธิบายไว้ในนโยบายนี้
      นโยบายนี้ไม่ได้แก้ไขหรือแทนที่ความยินยอมใด ๆ
      ที่ผู้ใช้งานอาจให้ไว้กับเราก่อนหน้านี้
      เราขอสงวนสิทธิ์ในการเปลี่ยนแปลงหรือปรับปรุงนโยบายนี้เป็นครั้งคราวและการเปลี่ยนแปลงใด
      ๆ
      ในนโยบายนี้จะมีผลเมื่อเราโพสต์นโยบายที่แก้ไขในเว็บไซต์และ/หรือแอปพลิเคชันมือถือของเรา
      ผู้ใช้งานสามารถตรวจสอบวันที่ประกาศที่ด้านล่างของนโยบายนี้
      เราสนับสนุนให้ผู้ใช้งานอ่านนโยบายนี้เมื่อใดก็ตามที่ผู้ใช้งานเยี่ยมชมเว็บไซต์ของเราหรือใช้แอปพลิเคชันมือถือของเราเพื่อให้แน่ใจว่าผู้ใช้งานได้รับทราบการเปลี่ยนแปลงอยู่เสมอ
    </p>
    <p>
      นโยบายความเป็นส่วนตัวฉบับนี้ ซึ่งต่อไปนี้จะเรียกว่า “นโยบาย”
      บังคับใช้ครั้งแรกเมื่อวันที่ 1 เมษายน 2564 โดยมีรายละเอียด ดังต่อไปนี้
    </p>
    <!--   ข้อ 1 -->
    <p><strong>ข้อ 1 คำนิยาม</strong></p>
    <p>ภายในนโยบายฉบับนี้</p>
    <p>
      (ก) “เว็บไซต์และแอปพลิเคชัน” หมายความว่า เว็บไซต์และแอปพลิเคชัน ชื่อว่า
      fit2work และมีที่อยู่เว็บไซต์ที่ https://fit2work.thaissf.org
      <br />(ข) “ผู้ควบคุมข้อมูล” หมายความว่า
      ผู้ให้บริการหรือเจ้าของเว็บไซต์และแอปพลิเคชัน ตามนโยบายฉบับนี้ อันได้แก่
      มูลนิธิสดศรี-สฤษดิ์วงศ์ เลขที่ 1168 ซ.พหลโยธิน 22 ถ.พหลโยธิน แขวงจอมพล
      เขตจตุจักร กรุงเทพฯ 10900 <br />(ค) “ผู้ประมวลผลข้อมูล” หมายความว่า
      บุคคลภายนอกซึ่งประมวลข้อมูลเพื่อประโยนช์หรือในนามของผู้ควบคุมข้อมูล <br />
      (ง) “ข้อมูล” หมายความว่า สิ่งที่สื่อความหมายให้รู้เรื่องราวข้อเท็จจริง
      ข้อมูล หรือสิ่งใดๆ
      ไม่ว่าการสื่อความหมายนั้นจะทำได้โดยสภาพของสิ่งนั้นเองหรือโดยผ่านวิธีการใดๆ
      และไม่ว่าจะได้จัดทำไว้ในรูปของเอกสาร แฟ้ม รายงาน หนังสือ แผนผัง แผนที่
      ภาพวาด ภาพถ่าย ฟิล์ม การบันทึกภาพหรือเสียง การบันทึกโดยเครื่องคอมพิวเตอร์
      โดยวิธีการทางอิเล็กทรอนิกส์ หรือวิธีอื่นใดที่ทำให้สิ่งที่บันทึกไว้ปรากฏได้
      <br />(จ) “ข้อมูลส่วนบุคคล” หมายความว่า ข้อมูลเกี่ยวกับบุคคลธรรมดาใดๆ
      ซึ่งทำให้สามารถระบุตัวของบุคคลนั้นได้ไม่ว่าทางตรงหรือทางอ้อม <br />
      (ฉ) “ข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Data)” หมายความว่า
      ข้อมูลส่วนบุคคลของผู้ใช้งานที่เกี่ยวกับเชื้อชาติ เผ่าพันธ์
      ความคิดเห็นทางการเมือง ความเชื่อในลัทธิ ศาสนา หรือปรัชญา พฤติกรรมทางเพศ
      ประวัติอาชญากรรม ข้อมูลสุขภาพ ความพิการ พันธุกรรม ข้อมูลชีวภาพ
      ข้อมูลภาพจำลองใบหน้า ม่านตา หรือลายนิ้วมือ ข้อมูลสหภาพแรงงาน
      หรือข้อมูลอื่นใดซึ่งคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลได้ประกาศตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลได้ประกาศให้เป็นข้อมูลข้อมูลส่วนบุคคลที่มีความอ่อนไหว
      <br />(ช) “ผู้ใช้งาน” หมายความว่า ท่าน ผู้เยี่ยมชม ผู้ใช้
      ผู้เป็นสมาชิกของเว็บไซต์และแอปพลิเคชัน
      ซึ่งเป็นเจ้าของข้อมูลส่วนบุคคลตามนโยบายฉบับนี้
    </p>
    <!-- /  ข้อ 1 -->

    <!--   ข้อ 2 -->
    <p><strong>ข้อ 2 ความยินยอมของผู้ใช้งาน</strong></p>
    <p>
      ในการเข้าใช้งานเว็บไซต์และแอปพลิเคชัน
      ผู้ใช้งานตกลงและให้ความยินยอมเกี่ยวกับการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล
      รวมถึงการส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ ดังต่อไปนี้
    </p>
    <p>
      <strong
        >(ก) วัตถุประสงค์แห่งการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล
        รวมถึงการส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ</strong
      >
    </p>
    <p>
      ผู้ใช้งานรับทราบ ตกลง
      และยินยอมให้ผู้ควบคุมข้อมูลและผู้ประมวลผลข้อมูลเก็บรวบรวมและใช้ข้อมูลส่วนบุคคล
      เพื่อวัตถุประสงค์ดังต่อไปนี้เท่านั้น
    </p>
    <p>
        <ul>
          <li>เมื่อผู้ใช้งานลงทะเบียนเป็นสมาชิกกับผ่านแบบฟอร์มใบสมัครไม่ว่าจะเป็นกระดาษหรือแบบฟอร์มอิเล็กทรอนิกส์หรือในเว็บไซต์และแอปพลิเคชัน</li>
          <li>เมื่อผู้ใช้งานเข้าระบบและใช้บริการใด ๆ บนเว็บไซต์และแอปพลิเคชัน</li>
          <li>เมื่อผู้ใช้งานโต้ตอบกับเราผ่านโทรศัพท์, SMS, แอพส่งข้อความ</li>
          <li>เมื่อผู้ใช้งานส่งข้อมูลส่วนตัวของผู้ใช้งานให้เราด้วยเหตุผลอื่นใด</li>
        </ul>
    </p>
    <p><strong>(ข) ข้อมูลส่วนบุคคลที่เก็บรวบรวมและใช้รวมถึงการส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ</strong></p>
    <p>
      ผู้ใช้งานรับทราบ ตกลง และยินยอมให้ผู้ควบคุมข้อมูลและผู้ประมวลผลข้อมูลเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลรวมถึงการส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ ดังต่อไปนี้เท่านั้น
    </p>
    <p>
    <ul>
        <li>ชื่อ  </li>
        <li>นามสกุล </li>
        <li>เบอร์โทรศัพท์ </li>
        <li>อีเมล </li>
    </ul>
    </p>
    <p>
      <strong>(ค) ระยะเวลาในการเก็บรวบรวมข้อมูล</strong>
    </p>

    <p>ผู้ใช้งานรับทราบ ตกลง และยินยอมให้ผู้ควบคุมข้อมูลและผู้ประมวลผลข้อมูลเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลรวมถึงการส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศเป็นระยะเวลาทั้งสิ้น 36 (สามสิบหก) เดือนนับจากวันที่ได้มีความยินยอมให้เก็บรวบรวมและใช้ข้อมูลส่วนบุคคลรวมถึงการส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศตามนโยบายฉบับนี้</p>
    <!--   /ข้อ 2 -->

  <!--   ข้อ 3 -->
    <p><strong>ข้อ 3 การเชื่อมโยงข้อมูลของผู้ใช้งานเว็บไซต์และแอปพลิเคชันกับผู้ให้บริการบุคคลที่สาม</strong></p>
    <p>ผู้ใช้งานรับทราบ ตกลง และยินยอมให้ผู้ควบคุมข้อมูลอาจการเชื่อมโยงข้อมูลของผู้ใช้งานเว็บไซต์และแอปพลิเคชันกับผู้ให้บริการบุคคลที่สาม โดยในการเชื่อมโยงหรือแบ่งปันข้อมูลต่อผู้ให้บริการบุคคลที่สามในแต่ละคราว ผู้ควบคุมข้อมูลจะแจ้งให้ผู้ใช้งานทราบว่าข้อมูลของผู้ใช้งานใดที่จะถูกเชื่อมโยงหรือแบ่งปันแก่ผู้ให้บริการบุคคลที่สาม ทั้งนี้ เมื่อผู้ใช้งานได้แสดงเจตนาโดยชัดแจ้งในการอนุญาตให้มีการเชื่อมโยงหรือแบ่งปันดังกล่าวนั้น อันรวมถึงแต่ไม่จำกัดเพียง การกดยอมรับ อนุญาต เชื่อมโยง แบ่งปันหรือการกระทำใดๆ อันมีลักษณะโดยชัดแจ้งว่าผู้ใช้งานได้ยินยอมในการเชื่อมโยงหรือแบ่งปันข้อมูลต่อผู้ให้บริการบุคคลที่สามนั้น</p>
    <!--  / ข้อ 3 -->

    <!--   ข้อ 4 -->
    <p>
      <strong>ข้อ 4 การติดตามพฤติกรรมการใช้งานเว็บไซต์และแอปพลิเคชันของผู้ใช้งาน</strong>
    </p>
    <p>
      ผู้ใช้งานรับทราบ ยินยอม และตกลงให้ผู้ควบคุมข้อมูลอาจใช้ระบบและ/หรือเทคโนโลยีดังต่อไปนี้ในการติดตามพฤติกรรมการใช้งานเว็บไซต์และแอปพลิเคชันของผู้ใช้งาน
    </p>
    <p>
      <ul>
        <li>เทคโนโลยี Cookies, การใช้งาน Pixel Tags และ Google Analytics Tag</li>
      </ul>
    </p>
    <p>
      ทั้งนี้ เพื่อวัตถุประสงค์ดังระบุต่อไปนี้เท่านั้นเพื่อพัฒนาการให้บริการที่ตรงความต้องการของผู้ใช้งาน
    </p>
  <!-- /  ข้อ 4 -->

<!--   ข้อ 5 -->
<p>
  <strong>ข้อ 5 การถอนความยินยอมของผู้ใช้งาน</strong>
</p>
<p>
  ผู้ใช้งานรับทราบว่าผู้ใช้งานมีสิทธิที่จะถอนความยินยอมใดๆ ที่ผู้ใช้งานได้ให้ไว้แก่ผู้ควบคุมข้อมูลตามนโยบายฉบับนี้ได้ ไม่ว่าเวลาใดโดยการดำเนินการ ดังต่อไปนี้
</p>
<p>
  (ก) แจ้งเป็นลายลักษณ์อักษรที่อีเมล pdpa@thainhf.org  หรือ เลือก “ถอนสิทธิ์การใช้งาน” ที่เมนูตั้งค่าเกี่ยวกับคุณ
  <br/>(ข) โดยผู้ใช้งานยังรับทราบอีกว่าเมื่อผู้ใช้งานได้ดำเนินการถอนความยินยอมแล้ว ผู้ใช้งานจะได้รับผลกระทบคือ ผู้ใช้งานจะถูกจำกัดสิทธิ์การถึงเว็บไซต์และแอปพลิเคชัน โดยจะเป็นเพียงการเข้ารับชมเว็บไซต์ในฐานะบุคคลทั่วไปเท่านั้นโดยที่ ผู้ใช้งานยังได้ตกลงยอมรับซึ่งผลแห่งการถอนความยินยอมนั้นทั้งสิ้น
</p>
<!--   /ข้อ 5 -->

<!--   ข้อ 6 -->
<p><strong>ข้อ 6 บัญชีผู้ใช้</strong></p>
<p>
  ในการใช้งานเว็บไซต์และแอปพลิเคชัน ผู้ควบคุมข้อมูลอาจจัดให้มีบัญชีผู้ใช้ของแต่ละผู้ใช้งานเพื่อการใช้งานเว็บไซต์และแอปพลิเคชัน โดยที่ผู้ควบคุมข้อมูลมีสิทธิแต่เพียงผู้เดียวในการอนุมัติเปิดบัญชีผู้ใช้ กำหนดประเภทบัญชีผู้ใช้ กำหนดสิทธิการเข้าถึงข้อมูลของแต่ละประเภทบัญชีผู้ใช้ สิทธิการใช้งานเว็บไซต์และแอปพลิเคชัน  เกี่ยวกับบัญชีผู้ใช้ หน้าที่และความรับผิดชอบของผู้ใช้งานซึ่งเป็นเจ้าของบัญชีผู้ใช้นั้นๆ
</p>
<p>
  ทั้งนี้ ผู้ใช้งานตกลงจะเก็บรักษาชื่อบัญชีผู้ใช้ รหัสผ่าน และข้อมูลใดๆ ของตนไว้เป็นความลับอย่างเคร่งครัด และตกลงจะไม่ยินยอมให้ รวมถึงใช้ความพยายามอย่างที่สุดในการป้องกันไม่ให้บุคคลอื่นใช้งานบัญชีผู้ใช้ของผู้ใช้งาน
</p>
<p>
  ในกรณีที่มีการใช้บัญชีผู้ใช้ของผู้ใช้งานโดยบุคคลอื่น ผู้ใช้งานตกลงและรับรองว่าการใช้งานโดยบุคคลอื่นดังกล่าวได้กระทำในฐานะตัวแทนของผู้ใช้งานและมีผลผูกพันเสมือนหนึ่งผู้ใช้งานเป็นผู้กระทำการเองทิ้งสิ้น
</p>
<!--/   ข้อ 6 -->


<!--   ข้อ 7-->
<p><strong>ข้อ 7 สิทธิของผู้ใช้งาน</strong></p>
<p>
  ในการเข้าใช้งานเว็บไซต์และแอปพลิเคชันตามนโยบายฉบับนี้และการให้ความยินยอมใดๆ ตามนโยบายฉบับนี้ ผู้ใช้งานได้รับทราบถึงสิทธิของตนในฐานะเจ้าของข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลเป็นอย่างดีแล้ว อันรวมถึงแต่ไม่จำกัดเพียงสิทธิของผู้ใช้งาน ดังต่อไปนี้
</p>
<p>
  (ก) ผู้ใช้งานอาจถอนความยินยอมที่ให้ไว้ตามนโยบายฉบับนี้เมื่อใดก็ได้ โดยการแจ้งเป็นลายลักษณ์อักษรแก่ผู้ควบคุมข้อมูลตามวิธีและช่องทางที่กำหนดในนโยบายฉบับนี้
  <br/>(ข) ผู้ใช้งานมีสิทธิการเข้าถึงและขอรับสำเนาข้อมูลส่วนบุคคลของตนหรือที่เกี่ยวข้องกับตนที่ผู้ควบคุมข้อมูลได้เก็บรวบรวมเอาไว้ตามนโยบายฉบับนี้
<br/>(ค) ผู้ใช้งานมีสิทธิได้รับการเปิดเผยจากผู้ควบคุมข้อมูลถึงการได้มาซึ่งข้อมูลส่วนบุคคลของตนหรือที่เกี่ยวข้องกับตนซึ่งตนไม่ได้ให้ความยินยอม หากว่ามีกรณีเช่นว่า
<br/>(ง) ผู้ใช้งานอาจให้ผู้ควบคุมข้อมูลส่งหรือโอนข้อมูลส่วนบุคคลของตนหรือที่เกี่ยวข้องกับตนไปยังผู้ควบคุมข้อมูลรายอื่น รวมถึงการขอรับข้อมูลที่ได้ส่งหรือโอนดังกล่าวโดยตรงจากผู้ควบคุมข้อมูลที่ส่งหรือโอนข้อมูลนั้นด้วย
<br/>(จ) ผู้ใช้งานอาจคัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของตนหรือที่เกี่ยวข้องกับตนได้ในกรณีดังต่อไปนี้
</p>
  <p class="margin-tab">
    (1) ผู้ควบคุมข้อมูลเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานด้วยความจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของผู้ควบคุมข้อมูลหรือของบุคคลอื่นซึ่งผู้ใช้งานอาจพิสูจน์ได้ว่าตนมีสิทธิดีกว่าผู้ควบคุมข้อมูล
    <br/>(2) ผู้ควบคุมข้อมูลเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานเพื่อเป็นการปฏิบัติตามกฎหมายของผู้ควบคุมข้อมูลซึ่งผู้ใช้งานอาจพิสูจน์ได้ว่าตนมีสิทธิดีกว่าผู้ควบคุมข้อมูล
    <br/>(3) ผู้ควบคุมข้อมูลเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลนั้นไปเพื่อวัตถุประสงค์เกี่ยวกับการตลาดแบบตรง
    <br/>(4) ผู้ควบคุมข้อมูลเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลนั้นไปเพื่อวัตถุประสงค์เกี่ยวกับการศึกษาวิจัยทางวิทยาศาสตร์ ประวัติศาสตร์ หรือสถิติ โดยที่การศึกษาวิจัยนั้นไม่มีความจำเป็นในการดำเนินการเพื่อก่อให้เกิดประโยชน์สาธารณะ
  </p>
  <p>
    (ฉ) ผู้ใช้งานอาจให้ผู้ควบคุมข้อมูลดำเนินการลบ ทำลาย หรือทำให้ข้อมูลไม่สามารถระบุตัวบุคคลผู้เป็นเจ้าของข้อมูลได้ ในกรณีดังต่อไปนี้
  </p>
  <p class="margin-tab">
      (1) เมื่อข้อมูลส่วนบุคคลหมดความจำเป็นในการเก็บรักษาไว้ตามวัตถุประสงค์ในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลนั้น
      <br/>(2) เมื่อผู้ใช้งานซึ่งเป็นเจ้าของข้อมูลส่วนบุคคลได้ถอนความยินยอมในการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลนั้นและผู้ควบคุมข้อมูลนั้นไม่มีอำนาจอื่นตามกฎหมายที่จะเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลนั้นได้อีกต่อไป
      <br/>(3) เมื่อผู้ใช้งานได้คัดค้านการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลนั้นโดยชอบด้วยกฎหมาย
      <br/>(4) เมื่อข้อมูลส่วนบุคคลได้ถูกเก็บรวบรวม ใช้ หรือเปิดเผยโดยไม่ชอบด้วยกฎหมาย กฎ ระเบียบ ข้อบังคับเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
  </p>
  <p>
    (ช) ผู้ใช้งานอาจให้ผู้ควบคุมข้อมูลระงับการใช้ข้อมูลส่วนบุคคลนั้นโดยยังคงเก็บรักษาเอาไว้ได้อยู่ ในกรณีดังต่อไปนี้
  </p>
  <p  class="margin-tab">
      (1) ผู้ควบคุมข้อมูลอยู่ในระหว่างการถูกตรวจสอบโดยคณะกรรมการผู้เชี่ยวชาญตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลซึ่งผู้ใช้งานได้ร้องเรียนให้มีการตรวจสอบดังกล่าว
      <br/>(2) ข้อมูลส่วนบุคคลได้ถูกเก็บรวบรวม ใช้ หรือเปิดเผยโดยไม่ชอบด้วยกฎหมาย กฎ ระเบียบ ข้อบังคับเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
      <br/>(3) ในกรณีที่ผู้ใช้งานมีความจำเป็นต้องการให้ผู้ควบคุมข้อมูลเก็บรักษาข้อมูลส่วนบุคคลของตนเอาไว้เพื่อประโยชน์ในสิทธิเรียกร้องของผู้ใช้งานเอง อันได้แก่ การก่อสิทธิเรียกร้องตามกฎหมายของผู้ใช้งาน การปฏิบัติตามหรือการใช้สิทธิเรียกร้องตามกฎหมาย หรือการยกขึ้นต่อสู้สิทธิเรียกร้องตามกฎหมาย ผู้ใช้งานอาจให้ผู้ควบคุมข้อมูลเพียงระงับการใช้ข้อมูลแทนการดำเนินการลบ ทำลาย หรือทำให้ข้อมูลไม่สามารถระบุตัวบุคคลผู้เป็นเจ้าของข้อมูลได้
      <br/>(4) ผู้ควบคุมข้อมูลอยู่ในระหว่างการพิสูจน์หรือตรวจสอบเพื่อปฏิเสธการคัดค้านการเก็บรวบรวม ใช้ หรือเผยแพร่ข้อมูลส่วนบุคคลของผู้ใช้งานตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลซึ่งผู้ใช้งานได้คัดค้านโดยชอบด้วยกฎหมายนั้น
  </p>
    <p>
      (ซ) เมื่อผู้ใช้งานพบเห็นว่าข้อมูลส่วนบุคคลของผู้ใช้งานผิด ล้าหลัง ไม่ชัดเชน ผู้ใช้งานมีสิทธิให้ผู้ควบคุมข้อมูลดำเนินการแก้ไขข้อมูลส่วนบุคคลนั้นให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิดได้
      <br/>(ฌ) ผู้ใช้งานอาจร้องเรียนต่อคณะกรรมการผู้เชี่ยวชาญตามกฎหมายคุ้มครองข้อมูลส่วนบุคคลในกรณีที่เกี่ยวกับการกระทำการฝ่าฝืนหรือการไม่ปฏิบัติตามกฎหมาย กฎ ระเบียบ ข้อบังคับเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคลของผู้ควบคุมข้อมูลและ/หรือผู้ประมวลผลข้อมูล
    </p>
  <!--  / ข้อ 7-->

<!--   ข้อ 8-->
    <p>
      <strong>ข้อ 8 การรักษาความมั่นคงปลอดภัย</strong>
    </p>
      <p>
        ในการเก็บรวบรวมและใช้ข้อมูลส่วนบุคคลตามนโยบายฉบับนี้ ผู้ควบคุมข้อมูลจะจัดให้มีมาตรการรักษาความมั่นคงปลอดภัยที่เหมาะสมเพื่อป้องกันการสูญหาย การเข้าถึง ใช้ เปลี่ยนแปลง แก้ไข หรือการเปิดเผยข้อมูลที่ไม่เป็นไปตามกฎมหาย ด้วยมาตรการ มาตรฐาน เทคโนโลยีและ/หรือด้วยระบบ ดังต่อไปนี้
      </p>
      <p>
         <ul>
          <li>•	กำหนดสิทธิ์การเข้าถึงข้อมูล (Access Right), การเข้ารหัสข้อมูล (Encryption) และ ระบบ Firewalls</li>
        </ul>
      </p>
    <p>
      รวมถึงการควบคุมให้ผู้ประมวลผลข้อมูลมีการรักษาความมั่นคงปลอดภัยของข้อมูลส่วนบุคคลที่ไม่น้อยไปกว่าที่กำหนดในนโยบายฉบับนี้ด้วย
    </p>
<!--  / ข้อ 8-->


<!--   ข้อ 9-->
    <p><strong>ข้อ 9 การแก้ไขปรับปรุงข้อมูลส่วนบุคคล</strong></p>
    <p>ผู้ควบคุมข้อมูลจะจัดให้มีระบบและมาตรการการตรวจสอบ ดังต่อไปนี้</p>
    <p>
      <br/>(ก) ดำเนินการปรับปรุงแก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด
      <br/>(ข) ลบ ทำลายข้อมูลส่วนบุคคลที่เกินระยะเวลาเก็บรวบรวมที่ผู้ใช้งานได้ให้ความยินยอมเอาไว้ และ
      <br/>(ค) ลบ ทำลายข้อมูลส่วนบุคคลที่ไม่เกี่ยวข้องกับการใช้ข้อมูลส่วนบุคคลดังกล่าวตามที่ผู้ใช้งานได้ให้ความยินยอมเอาไว้
    </p>
    <!-- /  ข้อ 9-->

    <!--   ข้อ 10-->
  <p><strong>ข้อ 10 การเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลตามกฎหมายคุ้มครองข้อมูลส่วนบุคคล</strong></p>
<p>
  ผู้ใช้งานรับทราบและตกลงว่าผู้ควบคุมข้อมูลอาจเก็บรวบรวม ใช้ และ/หรือเปิดเผยข้อมูลของผู้ใช้งานได้โดยไม่ต้องได้รับความยินยอมจากผู้ใช้งานก่อนล่วงหน้า ทั้งนี้เท่าที่จำเป็นและตราบเท่าที่เป็นไปตามวัตถุประสงค์และในกรณีดังต่อไปนี้เท่านั้น
</p>
<p>
  (ก) เพื่อให้บรรลุวัตถุประสงค์ที่เกี่ยวกับการจัดทำเอกสารประวัติศาสตร์หรือจดหมายเหตุเพื่อประโยชน์สาธารณะ หรือเกี่ยวกับการศึกษาวิจัยหรือสถิติซึ่งได้จัดให้มีมาตรการปกป้องที่เหมาะสมเพื่อคุ้มครองสิทธิและเสรีภาพของข้อมูลส่วนบุคคลของผู้ใช้งาน
   <br/>(ข) เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของบุคคลใดๆ
   <br/>(ค) เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้นเป็นคู่สัญญาหรือเพื่อใช้ในการดำเนินการตามคำขอของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลก่อนเข้าทำสัญญาดังกล่าวนั้น
   <br/>(ง) เป็นการจำเป็นเพื่อการปฏิบัติหน้าที่ในการดำเนินการเพื่อประโยชน์สาธารณะของผู้ควบคุมข้อมูลหรือปฏิบัติหน้าที่ในการใช้อำนาจรัฐที่ได้มอบให้แก่ผู้ควบคุมข้อมูลนั้น
   <br/>(จ) เป็นการจำเป็นเพื่อประโยชน์โดยชอบด้วยกฎหมายของผู้ควบคุมข้อมูลหรือของบุคคลอื่นซึ่งประโยชน์ดังกล่าวมีความสำคัญมากกว่าสิทธิขั้นพื้นฐานในข้อมูลส่วนบุคคลของผู้ใช้งานนั้น
   <br/>(ฉ) เป็นการปฏิบัติตามกฎหมายของผู้ควบคุมข้อมูล
</p>
<p>ทั้งนี้ ผู้ควบคุมข้อมูลจะบันทึกการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานตามวรรคก่อนหน้าไว้เป็นสำคัญ</p>
 <!--  / ข้อ 10-->

 <!--   ข้อ 11-->
<p><strong>ข้อ 11 การเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Data)</strong></p>
<p>
  ผู้ใช้งานรับทราบและตกลงว่านอกจากการเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลซึ่งผู้ใช้งานได้ให้ความยินยอมไว้โดยชัดแจ้งให้เก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลในวรรคก่อนแล้ว ผู้ควบคุมข้อมูลอาจเก็บรวบรวม ใช้และ/หรือเปิดเผยข้อมูลส่วนบุคคลที่มีความอ่อนไหว (Sensitive Data) ของผู้ใช้งานได้โดยไม่ต้องได้รับความยินยอมจากผู้ใช้งานก่อนล่วงหน้า ทั้งนี้เท่าที่จำเป็นและตราบเท่าที่เป็นไปตามวัตถุประสงค์และในกรณีดังต่อไปนี้เท่านั้น
</p>
<p>
  (ก) เพื่อป้องกันหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลซึ่งไม่สามารถให้ความยินยอมได้ ไม่ว่าด้วยเหตุใดก็ตาม
   <br/> (ข) เป็นข้อมูลที่เปิดเผยต่อสาธารณะด้วยความยินยอมโดยชัดแจ้งของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้น
    <br/>(ค) เป็นการจำเป็นเพื่อการก่อตั้ง การปฏิบัติตาม การใช้หรือการยกขึ้นต่อสู้ซึ่งสิทธิเรียกร้องตามกฎหมาย
    <br/>(ง) เป็นการจำเป็นในการปฏิบัติตามกฎหมายเพื่อให้บรรลุวัตถุประสงค์อันเกี่ยวกับ
</p>
<p class="margin-tab">
  (1) เวชศาสตร์ป้องกันหรืออาชีวเวชศาสตร์ การประเมินความสามารถในการทำงานของลูกจ้าง การวินิจฉัยโรคทางการแพทย์ การให้บริการด้านสุขภาพหรือด้านสังคม การรักษาทางการแพทย์ การจัดการด้านสุขภาพ หรือระบบและการให้บริการด้านสังคมสงเคราะห์
   <br/>(2) ประโยชน์สาธารณะด้านการสาธารณะสุข เช่น การป้องกันด้านสุขภาพจากโรคติดต่ออันตรายหรือโรคระบาดที่อาจติดต่อหรือแพร่เข้ามาในราชอาณาจักร หรือการควบคุมมาตรฐานหรือคุณภาพของยา เวชภัณฑ์ หรือเครื่องมือแพทย์ ซึ่งได้จัดให้มีมาตรการที่เหมาะสมและเจาะจงเพื่อคุ้มครองสิทธิเสรีภาพของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลโดยเฉพาะการรักษาความลับของข้อมูลส่วนบุคคลตามหน้าที่หรือตามจริยธรรมแห่งวิชาชีพ
   <br/>(3) การคุ้มครองแรงงาน การประกันสังคม หลักประกันสุขภาพแห่งชาติ สวัสดิการเกี่ยวกับการรักษาพยาบาลของผู้มีสิทธิตามกฎหมาย การคุ้มครองผู้ประสบภัยจากรถหรือการคุ้มครองทางสังคม ซึ่งการเก็บรวมรวมข้อมูลส่วนบุคคลของผู้ใช้งานนั้นเป็นสิ่งที่จำเป็นในการปฏิบัติตามสิทธิหรือหน้าที่ของผู้ควบคุมข้อมูลหรือผู้ใช้งานเจ้าของข้อมูล โดยได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิขั้นพื้นฐานและประโยชน์ของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้น
   <br/>(4) การศึกษาวิจัยทางวิทยาศาสตร์ ประวัติศาสตร์ หรือสถิติ หรือประโยชน์สาธารณะอื่น ทั้งนี้ ด้วยการเก็บรวบรวม ใช้ และ/หรือเปิดเผยเพียงเท่าที่จำเป็นและได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิขั้นพื้นฐานและประโยชน์ของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้นตามที่คณะกรรมการคุ้มครองข้อมูลส่วนบุคคลได้กำหนด
   <br/>(5) ประโยชน์สาธารณะที่สำคัญ โดยได้จัดให้มีมาตรการที่เหมาะสมเพื่อคุ้มครองสิทธิขั้นพื้นฐานและประโยชน์ของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้น
</p>
<p>ทั้งนี้ ผู้ควบคุมข้อมูลจะบันทึกการเก็บรวบรวม ใช้ หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งานตามวรรคก่อนหน้าไว้เป็นสำคัญ</p>
<!--  / ข้อ 11-->

<!--   ข้อ 12-->
<p><strong>ข้อ 12 การใช้งานเว็บไซต์และแอปพลิเคชันของบุคคลซึ่งอยู่ในความปกครอง อนุบาล หรือพิพักษ์ของผู้ใช้งาน</strong></p>
<p>
  ผู้ใช้งานรับรองว่าจะตนไม่ใช่และจะไม่ยินยอมให้บุคคลซึ่งเป็นบุคคลบกพร่องความสามารถตามกฎหมายดังต่อไปนี้ เยี่ยมชม ใช้งาน หรือเป็นสมาชิกของเว็บไซต์และแอปพลิเคชัน
</p>
<p>
  (ก) คนไร้ความสามารถซึ่งอยู่ในความอนุบาลของผู้ใช้งาน
  <br/>(ข) คนเสมือนไร้ความสามารถซึ่งอยู่ในความพิทักษ์ของผู้ใช้งาน
</p>
<p>
  ในกรณีที่ผู้ใช้งานยินยอมให้บุคคลดังกล่าวข้างต้นเยี่ยมชม ใช้งาน หรือเป็นสมาชิกของเว็บไซต์และแอปพลิเคชัน ผู้ใช้งานตกลงให้ถือว่าผู้ใช้งานได้ใช้อำนาจปกครอง อนุบาล หรือพิพักษ์ของบุคคลดังกล่าว แล้วแต่กรณี ในการตกลงและให้ความยินยอมตามนโยบายฉบับนี้ทั้งสิ้นเพื่อและในนามของบุคคลดังกล่าวด้วย
</p>
<!-- /  ข้อ 12-->

<!--   ข้อ 13-->
<p><strong>ข้อ 13 การส่งหรือโอนข้อมูลส่วนบุคคลไปยังต่างประเทศ</strong></p>
<p>
  ผู้ควบคุมข้อมูลอาจส่งหรือโอนข้อมูลส่วนบุคคลของผู้ใช้งานไปยังต่างประเทศได้ในกรณีดังต่อไปนี้
</p>
<p>
  (ก) ประเทศปลายทางหรือองค์การระหว่างประเทศที่รับข้อมูลส่วนบุคคลนั้นมีมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่เพียงพอตามที่กฎหมาย กฎ ระเบียบ ข้อบังคับเกี่ยวกับการคุ้มครองข้อมูลส่วนบุคคล
  <br/>(ข) ได้รับความยินยอมจากเจ้าของข้อมูลส่วนบุคคล โดยที่ผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลได้รับแจ้งและรับทราบถึงมาตรฐานการคุ้มครองข้อมูลส่วนบุคคลที่ไม่เพียงพอของประเทศปลายทางหรือองค์การระหว่างประเทศที่รับข้อมูลนั้นแล้ว
  <br/>(ค) เป็นการปฏิบัติตามกฎหมาย
  <br/>(ง) เป็นการจำเป็นเพื่อการปฏิบัติตามสัญญาซึ่งผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลเป็นคู่สัญญานั้นหรือเพื่อใช้ในการดำเนินการตามคำขอของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลก่อนการเข้าทำสัญญานั้น
  <br/>(จ) เป็นการกระทำการตามสัญญาระหว่างผู้ควบคุมข้อมูลกับบุคคลอื่นโดยเป็นไปเพื่อประโยชน์ของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้น
  <br/>(ฉ) เพื่อป้องกับหรือระงับอันตรายต่อชีวิต ร่างกาย หรือสุขภาพของผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้นหรือบุคคลใดๆ เมื่อเจ้าของข้อมูลส่วนบุคคลไม่สามารถให้ความยินยอมในขณะนั้นได้
  <br/>(ช) เป็นการจำเป็นเพื่อการดำเนินภารกิจเพื่อประโยชน์สาธารณะที่สำคัญ
</p>
<!--  / ข้อ 13-->

<!--   ข้อ 14-->
<p><strong>ข้อ 14 การแจ้งเตือนเหตุการละเมิดข้อมูลส่วนบุคคล</strong></p>
<p>
  ในกรณีที่ผู้ควบคุมข้อมูลทราบถึงการละเมิดข้อมูลส่วนบุคคลไม่ว่าจะมีการละเมิดโดยบุคคลใด ผู้ควบคุมข้อมูลจะดำเนินการดังต่อไปนี้
</p>
<p>
  (ก) ในกรณีมีความเสี่ยงที่จะมีผลกระทบต่อสิทธิหรือเสรีภาพของบุคคลใดๆ ผู้ควบคุมข้อมูลจะแจ้งเหตุการละเมิดข้อมูลส่วนบุคคลดังกล่าวต่อสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคล โดยไม่ชักช้าเท่าที่จะสามารถกระทำได้ภายใน 72 (เจ็ดสิบสอง) ชั่วโมงนับแต่ทราบเหตุ
  <br/>(ข) ในกรณีมีความเสี่ยงที่จะมีผลกระทบอย่างสูงต่อสิทธิหรือเสรีภาพของบุคคลใดๆ ผู้ควบคุมข้อมูลจะแจ้งเหตุการละเมิดข้อมูลส่วนบุคคลดังกล่าวและแนวทางการเยียวยาต่อสำนักงานคณะกรรมการคุ้มครองข้อมูลส่วนบุคคลและต่อผู้ใช้งานเจ้าของข้อมูลส่วนบุคคลนั้น โดยไม่ชักช้าเท่าที่จะสามารถกระทำได้ภายใน 72 (เจ็ดสิบสอง) ชั่วโมงนับแต่ทราบเหตุ
</p>
<!-- /  ข้อ 14-->

<!--   ข้อ 15-->
<p><strong>ข้อ 15 การร้องเรียนและการแจ้งปัญหาเกี่ยวกับข้อมูลส่วนบุคคล</strong></p>
<p>
  ผู้ใช้งานอาจร้องเรียนและรายงานปัญหาเกี่ยวกับข้อมูลส่วนบุคคล อันรวมถึงแต่ไม่จำกัดเพียง การขอให้ผู้ควบคุมข้อมูลแก้ไขปรับปรุงข้อมูลให้เป็นปัจจุบันและ/หรือให้ถูกต้อง การคัดค้านการเก็บรวบรวมข้อมูล หรือระงับการใช้ข้อมูล ได้ที่ช่องทางดังต่อไปนี้
</p>
<!--  / ข้อ 15-->

<p><strong>Contact Center: pdpa@thainhf.org </strong></p>
<p><strong>โทรศัพท์ 02-511-5855 </strong></p>
<p><strong>หรือโทรสาร 02-939-2122 </strong></p>

    <p>
      <Checkbox v-model="isAcceptes" :binary="true" /> ข้าพเจ้ารับทราบดีกว่า
      หากข้าพเจ้าไม่ตกลงยอมรับข้อกำหนดและเงื่อนไขนี้
      ผู้ให้บริการสงวนสิทธิไม่ให้บริการแก่ข้าพเจ้าได้
    </p>
    <template #footer>
      <Button
        class="p-button-success"
        label="ยอมรับ"
        icon="pi pi-check"
        @click="() => $emit('onConsent', true)"
        autofocus
        :disabled="!isAcceptes"
      />
    </template>
  </Dialog>
</template>

<script>
export default {
  name: 'consent',
  props: [],
  data() {
    return {
      isAcceptes: false
    }
  },
  computed: {},
  methods: {}
}
</script>

<style>
.margin-tab {
  margin-left: 20px;
}
</style>