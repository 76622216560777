<template>
  <div class="p-grid p-jc-center">
    <div class="p-col-12 p-md-3">
      <Fieldset legend="ลงชื่อเข้าใช้งานระบบ">
        <Message severity="error" v-if="!$store.state.pdpa.isAccepted"
          >ไม่สามารถเข้าใช้งานได้เนื่องจากไม่ยอมรับข้อตกลงและเงื่อนไขการใช้งานระบบ</Message
        >
        <form @submit.prevent="handleAuthentication" id="validity">
          <div class="p-field p-grid">
            <div class="p-col-12 p-fluid">
              <span class="p-input-icon-left">
                <i class="pi pi-user" />
                <InputText
                  type="text"
                  class="p-inputtext-lg"
                  v-model="authenticate.username"
                  :class="{ 'p-invalid': !authenticate.username }"
                  placeholder="ชื่อเข้าใช้งาน"
                  :required="true"
                  id="username"
                />
              </span>
            </div>
            <div class="p-col-12">
              <InlineMessage v-if="!authenticate.username"
                >ห้ามว่าง</InlineMessage
              >
            </div>
          </div>
          <div class="p-field p-grid">
            <div class="p-col-12 p-fluid">
              <span class="p-input-icon-left">
                <i class="pi pi-key" />
                <Password
                  class="p-inputtext-lg"
                  v-model="authenticate.password"
                  :class="{ 'p-invalid': !authenticate.password }"
                  placeholder="รหัสผ่าน"
                  :required="true"
                  toggleMask
                  :feedback="false"
                  id="password"
                />
              </span>
            </div>
            <div class="p-col-12">
              <InlineMessage v-if="!authenticate.password"
                >ห้ามว่าง</InlineMessage
              >
            </div>
          </div>
          <div class="p-d-flex p-jc-center">
            <Button
              label="เข้าสู่ระบบ"
              iconPos="left"
              type="submit"
              class="p-button-success"
              :icon="[isLoading ? 'pi pi-spin pi-spinner' : 'pi pi-check']"
            />
            <router-link :to="{ path: '/forgot/password' }">
              <Button
                label="ลืมรหัสผ่าน"
                icon="pi pi-key"
                iconPos="left"
                class="p-button-link"
              />
            </router-link>
          </div>
        </form>
      </Fieldset>
    </div>
  </div>
  <Consent @onConsent="onConsent" />
</template>
<script>
import * as moment from 'moment'
import Consent from '../components/pdpa/Consent'
import { initCustomValidity } from '@/HTMLElement'
export default {
  name: 'login',
  layout: 'Public',
  data() {
    return {
      isLoading: false,
      authenticate: {
        username: null,
        password: null
      }
    }
  },
  created() {
    const { redirect } = this.$route.query
    console.log('redirect ::==', redirect)
  },
  mounted() {
    initCustomValidity('validity')
  },
  methods: {
    async onConsent(isAccepted) {
      const { dateFormatGet, dateFormatSave } = this.$primevue.config.locale
      const {
        codeId: userId,
        userLevel: level,
        area
      } = this.$store.getters['oauth/session']
      //alert('isAccepted ::==' + JSON.stringify(isAccepted))
      this.$store.commit('pdpa/setPdpa', {
        isAccepted: isAccepted,
        isOpen: false
      })

      const consent = this.$store.state.oauth.consent
      console.log('consent ::==' + JSON.stringify(consent))

      let payload = {
        ...consent,
        consentStatus: isAccepted ? 'A' : 'R',
        acceptDtm: moment().format(dateFormatSave),
        user: {
          codeId: userId
        }
      }
      if (isAccepted) {
        // save consent status to database
        const {
          status,
          code,
          data: consent
        } = await this.$store.dispatch('pdpa/saveUserConsent', payload)
        //console.log('code ::==', code)
        //console.log('code ::==', code)
        console.log('consent ::==', consent)
        if (code == 200) {
          localStorage.setItem('consent', JSON.stringify(consent))
          this.$toast.add({
            severity: 'success',
            summary: 'สถานะ Login เข้าใช้งานระบบ',
            detail: 'เข้าใช้งานระบบสำเร็จ',
            life: 3000
          })

          // save activity log
          await this.$store.dispatch('activity/saveActionActivityLog', {
            actMenu: 'LOGIN'
          })

          setTimeout(() => {
            this.redirect()
          }, 500)
        } else {
          this.$toast.add({
            severity: 'error',
            summary: 'สถานะ Login เข้าใช้งานระบบ',
            detail: 'ระบบไม่สามารถให้บริการได้ กรุณาติดต่อเจ้าหน้าที่ดูแล',
            life: 3000
          })
        }
      }
    },

    async handleAuthentication() {
      const { dateFormatGet, dateFormatSave } = this.$primevue.config.locale
      try {
        this.isLoading = true
        const {
          access_token,
          code,
          message: id
        } = await this.$store.dispatch(
          'oauth/handleAuthentication',
          this.authenticate
        )
        console.log('access_token ::==', access_token)
        this.isLoading = false
        if (access_token) {
          // check consent (PDPA)

          localStorage.setItem('jwt', access_token)
          const { user, consent } = await this.$store.dispatch(
            'oauth/getUserInfo'
          )
          console.log('user ::==', JSON.stringify(user))
          console.log('consent ::==', JSON.stringify(consent))
          if (user) {
            localStorage.setItem('user', JSON.stringify(user))
            if (consent) {
              localStorage.setItem('consent', JSON.stringify(consent))
              const { consentStatus, user } = consent
              if ('A' == consentStatus) {
                this.$toast.add({
                  severity: 'success',
                  summary: 'สถานะ Login เข้าใช้งานระบบ',
                  detail: 'เข้าใช้งานระบบสำเร็จ',
                  life: 3000
                })
                // save activity log
                const {
                  codeId: userId,
                  userLevel: userLevel,
                  area
                } = this.$store.getters['oauth/session']
                await this.$store.dispatch('activity/saveActionActivityLog', {
                  actMenu: 'LOGIN'
                })
                setTimeout(() => {
                  this.redirect()
                }, 500)
              } else {
                this.$store.commit('pdpa/setOpen', true)
              }
            } else {
              this.$store.commit('pdpa/setOpen', true)
            }
          } else {
            this.$toast.add({
              severity: 'error',
              summary: 'สถานะ Login เข้าใช้งานระบบ',
              detail: 'ไม่พบข้อมูลผู้ใช้งานในระบบ',
              life: 3000
            })
          }
        } else {
          console.log('code ::==', code, '  message ::==' + id)
          let message = 'ระบบไม่สามารถให้บริการได้ กรุณาติดต่อเจ้าหน้าที่ดูแล'
          if (code.indexOf('401') > -1) {
            if ('99' == id) {
              message = `User ของคุณถูกระงับสิทธิ์การใช้งาน เนื่องจากเคยพยายามเข้าใช้งานระบบเกิน 5 ครั้ง
                  กรุณาเมนูลืมรหัสผ่านเพื่อทำการเปลี่ยนรหัสผ่านเพื่อใช้งานอีกครั้ง`
            } else {
              message = `User ของคุณถูกระงับสิทธิ์การใช้งานเนื่องจากได้พยายามเข้าใช้งานโดยใช้ user name 
                  หรือ password ไม่ถูกต้องระบบเกิน 5 ครั้ง
                  กรุณาเมนูลืมรหัสผ่านเพื่อทำการเปลี่ยนรหัสผ่านเพื่อใช้งานอีกครั้ง`
            }
          }
          this.$toast.add({
            severity: 'error',
            summary: 'สถานะ Login เข้าใช้งานระบบ',
            detail: message,
            life: 3000
          })
        }
      } catch (error) {
        this.isLoading = false
        const { message: msg } = error
        console.log('login error ::==', error)
        let message = 'ระบบไม่สามารถให้บริการได้ ขออภัยในความไม่สะดวก'
        if (msg.indexOf('401') > -1) {
          message = 'ชื่อเข้าใช้งาน หรือ รหัสผ่าน ไม่ถูกต้อง'
        }
        this.$toast.add({
          severity: 'error',
          summary: 'เกิดข้อผิดพลาดจากระบบ',
          detail: message,
          life: 3000
        })
      }
    },
    redirect() {
      const { redirect } = this.$route.query
      console.log('redirect ::==', redirect)
      if (redirect) {
        this.$router.push(redirect)
      } else {
        this.$router.push('/dashboard')
      }
    }
  },
  components: {
    Consent
  }
}
</script>